<template>
  <fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="loading"
      :loading-text="$t('common.loadingData')"
      :no-data-text="$t('common.noData')"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-4">
          <v-toolbar-title>
            {{ $t('specialBonuses.specialBonuses') }}
          </v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-btn
            small
            fab
            depressed
            color="primary"
            @click="createSpecialBonus()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <special-bonuses-list-filter
          :apply-filters="applyFilters"
          :applied-filters="filters"
        ></special-bonuses-list-filter>
      </template>
      <template v-slot:item.text="{ item }">
        {{ item.text[currentUser.lang] | truncate(50) }}
      </template>
      <template v-slot:item.websiteUrl="{ item }">
        <a v-if="item.websiteUrl" :href="item.websiteUrl" target="blank">
          {{ item.websiteUrl | truncate(50) }}
        </a>
        <span v-else>-</span>
      </template>
      <template v-slot:item.imageUrl="{ item }">
        <a v-if="item.imageUrl" :href="item.imageUrl" target="blank">
          {{ item.imageUrl | truncate(50) }}
        </a>
        <span v-else>-</span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-tooltip top :color="getApprovalColor(item.status)">
          <template v-slot:activator="{ on }">
            <v-icon x-small :color="getApprovalColor(item.status)" v-on="on">
              mdi-circle
            </v-icon>
          </template>
          <span>{{ item.status }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.createdBy="{ item }">
        <span>
          {{ item.createdBy | truncate(20) }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          x-small
          fab
          depressed
          color="primary"
          class="mr-1"
          @click="updateSpecialBonus(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="canDelete"
          x-small
          fab
          depressed
          color="error"
          class="mr-1"
          @click="deleteSpecialBonus(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import ManageSpecialBonusModal from './ManageSpecialBonusModal';
import SpecialBonusesListFilter from './SpecialBonusesListFilter';
import ConfirmModal from '../../shared/components/ConfirmModal';
import specialBonusesMapper from '../mappers/special.bonuses.mapper';
import approvalStatusesMixin from '../../shared/mixins/approval.statuses.mixin';
import listItemsMixin from '../../shared/mixins/list.items.mixin';
import {
  GET_SPECIAL_BONUSES,
  ADD_SPECIAL_BONUS,
  UPDATE_SPECIAL_BONUS,
  DELETE_SPECIAL_BONUS
} from '../store/types';
import resources from '../../../enums/resources';
import aclMixin from '../../shared/mixins/acl.mixin';

export default {
  name: 'special-bonuses-list',
  components: {
    SpecialBonusesListFilter
  },
  computed: {
    ...mapGetters({
      items: 'specialBonuses',
      totalItems: 'totalSpecialBonuses',
      currentUser: 'currentUser'
    })
  },
  mixins: [aclMixin, listItemsMixin, approvalStatusesMixin],
  data() {
    return {
      loading: true,
      feature: resources.specialBonuses,
      headers: [
        {
          text: this.$i18n.t('common.text'),
          value: 'text',
          sortable: false
        },
        {
          text: this.$i18n.t('common.websiteUrl'),
          value: 'websiteUrl',
          sortable: false
        },
        {
          text: this.$i18n.t('common.imageUrl'),
          value: 'imageUrl',
          sortable: false
        },
        {
          text: this.$i18n.t('common.status'),
          value: 'status',
          sortable: false
        },
        {
          text: this.$i18n.t('common.createdBy'),
          value: 'createdBy',
          sortable: false
        },
        {
          text: this.$i18n.t('common.actions'),
          value: 'action',
          sortable: false
        }
      ]
    };
  },
  methods: {
    async getItems(params) {
      this.loading = true;
      await this.$store.dispatch(GET_SPECIAL_BONUSES, params);
      this.loading = false;
    },
    async createSpecialBonus() {
      const payload = await this.$root.$modal.show(ManageSpecialBonusModal);

      if (!payload) return;

      await this.$store.dispatch(ADD_SPECIAL_BONUS, payload);
      this.resetList();

      this.$notify({
        type: 'success',
        text: this.$i18n.t('specialBonuses.createSuccess')
      });
    },
    async updateSpecialBonus(specialBonus) {
      const payload = await this.$root.$modal.show(ManageSpecialBonusModal, {
        selectedSpecialBonus:
          specialBonusesMapper.transformSpecialBonusToUpdateModel(specialBonus)
      });

      if (!payload) return;

      await this.$store.dispatch(UPDATE_SPECIAL_BONUS, payload);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('specialBonuses.updateSuccess')
      });
    },
    async deleteSpecialBonus(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('specialBonuses.deleteConfirmText')
      });

      if (!confirmed) return;

      await this.$store.dispatch(DELETE_SPECIAL_BONUS, id);

      this.resetList(false);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('specialBonuses.deleteSuccess')
      });
    }
  }
};
</script>
