<template>
  <v-container class="pb-0">
    <v-row>
      <v-col md="4" sm="12">
        <v-text-field
          v-model="filters.text"
          :label="$t('common.text')"
          solo
          dense
        ></v-text-field>
      </v-col>
      <v-col md="3" sm="12">
        <v-select
          v-model="filters.status"
          :items="statuses"
          :label="$t('common.selectStatus')"
          solo
          dense
        ></v-select>
      </v-col>
      <v-col md="2" xs="6">
        <v-btn color="default" block @click="reset()">
          {{ $t('common.reset') }}
        </v-btn>
      </v-col>
      <v-col md="2" xs="6">
        <v-btn color="primary" block @click="filter()">
          {{ $t('common.filter') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import approvalStatuses from '../../../enums/approval.statuses';
import listFilterMixin from '../../shared/mixins/list.filter.mixin';

export default {
  name: 'special-bonuses-list-filter',
  mixins: [listFilterMixin],
  data() {
    return {
      defaultFilters: {
        text: null,
        status: null
      },
      statuses: [
        {
          text: this.$i18n.t('common.selectStatus'),
          value: ''
        },
        ...Object.values(approvalStatuses).map(status => ({
          text: this.$i18n.t(`common.approvalStatuses.${status}`),
          value: status
        }))
      ]
    };
  }
};
</script>
