<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-text>
        <special-bonuses-list></special-bonuses-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SpecialBonusesList from '../components/SpecialBonusesList';

export default {
  name: 'special-bonuses-page',
  components: {
    SpecialBonusesList
  }
};
</script>
