<template>
  <v-card>
    <v-card-title>
      <span class="headline font-weight-light" v-if="specialBonus.id">
        {{ $t('specialBonuses.editSpecialBonus') }}
      </span>
      <span class="headline font-weight-light" v-else>
        {{ $t('specialBonuses.createSpecialBonus') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-form ref="manageSpecialBonusForm">
        <h3 class="pb-1">{{ $t('common.text') }}</h3>
        <v-textarea
          solo
          v-model="specialBonus.text.en"
          :rules="[rules.required]"
          :label="$t('languages.en')"
          class="pl-3"
        ></v-textarea>
        <v-textarea
          solo
          v-model="specialBonus.text.bg"
          :rules="[rules.required]"
          :label="$t('languages.bg')"
          class="pl-3"
        ></v-textarea>
        <v-text-field
          solo
          :label="$t('common.websiteUrl')"
          v-model="specialBonus.websiteUrl"
          :rules="[rules.websiteUrl]"
        ></v-text-field>
        <v-text-field
          solo
          :label="$t('common.imageUrl')"
          v-model="specialBonus.imageUrl"
          :rules="[rules.imageUrl]"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from '../../../core/validators';

export default {
  name: 'manage-special-bonus-modal',
  props: {
    selectedSpecialBonus: Object
  },
  data() {
    return {
      specialBonus: {
        text: {
          en: '',
          bg: ''
        },
        websiteUrl: '',
        imageUrl: ''
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        websiteUrl: validators.isUrl(this.$i18n.t('common.invalidUrl')),
        imageUrl: validators.isUrl(this.$i18n.t('common.invalidUrl'))
      }
    };
  },
  created() {
    if (this.selectedSpecialBonus) {
      this.specialBonus = this.selectedSpecialBonus;
    }
  },
  methods: {
    async save() {
      if (!this.$refs.manageSpecialBonusForm.validate()) {
        return;
      }

      this.$emit('close', this.specialBonus);
    }
  }
};
</script>
