function transformSpecialBonusToUpdateModel(specialBonus) {
  return {
    id: specialBonus.id,
    text: {
      en: specialBonus.text.en,
      bg: specialBonus.text.bg
    },
    websiteUrl: specialBonus.websiteUrl,
    imageUrl: specialBonus.imageUrl
  };
}

export default {
  transformSpecialBonusToUpdateModel
};
